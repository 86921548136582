
.desktopBills{
    width: 45%;
}

@media only screen and (max-width: 600px) {
    .desktopBills{
        display: none;
    }
}

