.planning-date-program{
    width: 100%;
    height: 270px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    /* background-color: red; */
}

.planning-row-container{
    width: 80%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    /* background-repeat: no-repeat;
    background-size: contain;
    background-position: center; */
    margin-left: auto;
    margin-right: auto;
    /* background-color: red; */
}

.planning-row-container-bg{
    display: none;
}

.planning-row-container-circle{
    display: none;
}

.planning-row-container-bg img{
    height: 100%;
    width: 100%;
}

.planning-date-program-bg{
    position: absolute;
    width: 100%;
    height: 100%;
}

.planning-date-program-bg img{
    width: 100%;
    height: 100%;

}

.planning-section{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 70px;
    margin-bottom: 100px;
}

.planning-date-section{
    /* background-color: red; */
    display: flex;
    justify-content: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.planning-month-section{
    font-family: 'Amatica SC';
    color: #333333;
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
}

@media (max-width: 600px){
    .planning-date-program-bg{
        display: none;
    }

    .planning-row-container-circle{
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: center;
    }

    .planning-row-container{
        /* transform: rotate(90deg); */
        flex-direction: column;
        height: max-content;
        position: relative;
    }

    .planning-row-container-bg{
        display: block;
        position: absolute;
        height: 100%;
        width: 100%;
    }

    .planning-date-program{
        display: flex;
        flex-direction: column;
        height: max-content;
        padding-top: 20px;
        padding-bottom: 20px;
        gap: 40px;
        
    }
}