*{
    padding: 0;
    margin: 0;
}

footer{
    width: 100%;
    height: fit-content;
    background-image: url('./../../assets/images/wave.svg');
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 13%;
    padding-bottom: 2%;
}

.upper_side{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 85vw;
}

.description{
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

footer .logo{
    height: 100px;
    width: 85px;
}

.desc_content{
    width: 30vw;
    font-family: 'Arimo';
    color: #BFBFBF;
    font-size: clamp(0.9rem, 2vw, 1.1rem);
}

.contacts{
    margin-top: 3%;
    width: 30vw;
}

.contact_content{
    font-family: 'FuturaBoldCondensedBT';
    font-size: clamp(1rem, 3vw, 1.8rem);
    color: #fff;
}

.icon{
    background-color: #fff;
    color: #8A422D;
    width: 20px;
    height: 20px;
    padding: 8px;
    border-radius: 20px;
    margin-right: 12px;

    cursor: pointer;
}

.icons{
    margin-top: 10px;
}

.navigations{
    color: #fff;
    display: flex;
    flex-direction: row;
    font-family: 'FuturaBoldCondensedBT';
    gap: 15px;
    justify-content: center;

    margin-top: 2%;
}

.navSection{
    cursor: pointer;
    color: #fff;
    text-decoration: none;
}

.copyright{
    color: #fff;
    justify-content: center;
    display: flex;
    margin-top: 10px;
}

.subCpyRight{
    font-family: 'Arimo';
}

.small{
    display: none;
}

@font-face{
    font-family: "FuturaBoldCondensedBT";
    src: local('FuturaBoldCondensedBT'), url('./../../assets/fonts/FuturaBoldCondensedBT.ttf');
}

@media only screen and (max-width: 900px) {
    footer{
        padding-top:25%;
    }
}

@media only screen and (max-width: 1100px) {
    footer{
        padding-top:16%;
    }
}

@media only screen and (max-width: 550px){
    footer{
        background-image: url('./../../assets/images/wave_small.svg');
        padding-top:120px;
    }

    .desc_content{
        display: none;
    }

    .contacts{
        display: none;
    }

    .upper_side{
        justify-content: center;
        width: 100%;
    }

    .navigations{
        margin-top: 50px;
        flex-wrap: wrap;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    .copyright{
        margin-top: 40px;
        font-size: 0.9rem;
    }

    .small{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
    }
    .small .icons{
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 50px;
    }
}