@import url('https://fonts.googleapis.com/css2?family=Arimo&display=swap');


.aboutlocation{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 60px;
  flex-wrap: wrap;
}


/* @media (min-width: 700px) {
    .about{
        display: grid;
    }
    .pictures{
        margin-left: 30px;
        grid-row-start:1 ;
        grid-column-start: 1;
        grid-column-end: 3;
    }
    .text{
        padding-left: 20px;
        grid-column-start: 4;
        grid-column-end: 5;
        max-width: 500px ;
    }
} */
.text{
    padding-top: 60px;
  /* display: inline-grid; */
  /* vertical-align: right; */
}
.text h5{
    font-family: 'Futura';
    font-style: normal;
    font-stretch: condensed;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;

    /* identical to box height, or 178% */
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #80B3E8;
}
.text h1{
    font-family: 'Futura';
    font-style: normal;
    font-stretch: condensed;
    font-weight: 600;
    font-size: 56px;
    line-height: 64px;

    letter-spacing: -1px;

    color: #333333;
}
.text p{
  font-family: 'Arimo', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #333333;
  padding-bottom: 10px;
}

.text-left{
    display: inline-block;
    padding-bottom: 20px;
}
.tn-pictures{
    text-align: center;
}

@media (min-width: 750px) {
    .tn-pictures{
        display: grid;
    }
    .big-image{
        grid-row-start:1 ;
        grid-row-end:3 ;
        grid-column-start: 1;
        grid-column-end: 3;
        }
    .small-img{
        margin: 0px 0px 24px 30px;
    }
    .small-img1{
        height: 220px ;
        grid-row-start:1 ;
        grid-column-start: 3;
        grid-column-end: 3;
    }
    .small-img2{
        grid-row-start:2 ;
        grid-column-start: 3;
        grid-column-end: 3;
    }
}

@media (max-width: 750px){
    .big-image{
        margin: 1vh;
        width: 86vw;
    }
    .small-img{
        margin: 1vh;
        width: 86vw;
    }

}