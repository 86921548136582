.information-page-paragraph {
    font-family: "Futura";
    color: #333333;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 32px;
    width: 70vw;
    margin: auto;
}
