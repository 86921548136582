input[type='radio'] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: #fff;
    /* Not removed via appearance */
    margin: 0;
}

/*
 * Author: http://stuffandnonsense.co.uk/blog/about/hardboiled_css3_media_queries/
 */

/* Smartphones (portrait and landscape) ----------- */
@media all and (max-width: 768px) {
    .MainActivityNavBarItems input[type='radio']:checked + label {
        font-size: 1.2em;
        text-shadow: white 0 0 2px;
    }
    .MainActivityNavBarItems {
        width: 100%;
        text-align: center;
        display: grid;
        grid-template-rows: 0 100%;
        justify-items: center;
        align-items: center;
        color: white;
        font-size: 1.1em;

    }
    .MainActivityNavBar {
        display: flex;
        position: fixed;
        bottom: 0;
        height: 50px;
        overflow: hidden;
        width: 100%;
        justify-content: space-around;
        background: radial-gradient(196.77% 386.32% at 11.46% -96.77%, #b03842 0%, #cb8b4e 100%);
        z-index:100;
            /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    }
    .selectedItem {
        background-color: #ffffff15;
    }
}
@media all and (min-width: 769px) {
    .MainActivityNavBar {
        display: flex;
        width: 100%;
        height: 36.5px;
        justify-content: center;
        align-items: flex-end;
        user-select: none;
        z-index: 1;
        position: relative;
    }
    .MainActivityNavBarItems {
        background-color: #d9d9d9;
        margin: 0 -5px;
        box-shadow: -2px -4px 5px rgba(0, 0, 0, 0.226);
        border-radius: 300px 300px 0 0;
        width: 18%;
        height: 30px;
        display: grid;
        grid-template-rows: 0 100%;
        justify-items: center;
        align-items: center;
        color: rgb(0, 0, 0);
        font-size: 1.1em;
        transition: 100ms;
        transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
        z-index: 0;
        box-sizing: content-box;
    }
    .selectedItem {
        box-shadow: 0px 0px 0px;
        background-color: #cb8b4e;
        color: white;
        /* position: relative;
        bottom: -2.5px; */
        width: 20%;
        height: 36.5px;
        margin: 0 -15px;
        border-width: 3px 3px 0px 3px;
        border-style: solid;
        border-color: #cb8b4e;
        border-radius: 300px 300px 0px 0px;
        font-size: 1.3em;
        z-index: 2;
    }
}
