.slider-card{
    width: 320px;
    height: 400px;
    border-radius: 10px;
    display: inline-block;
    margin-left: 15px;
    margin-right: 15px;
    cursor: default;
}

.slider-card-image{
    width: 100%;
    height: 400px;
    border-radius: 10px;
}
.slider-card-info{
    width: 80%;
    height: 150px;
    border-radius: 8px;
    background: rgb(255, 255, 255);
    position: relative;
    right: -30px;
    bottom: -75%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}


.slider-card-name{
    margin: 10px 0px 5px 7px;
    font-weight: 500;
    font-size: 25px;
}
.slider-card-position{
    margin: 5px 0px 5px 5px;
    font-weight: 250;
    font-size: 19px;

}
.slider-card-position,.slider-card-name{
    text-align: center;


}
.slider-card-line{
    margin-left: 10%;
}
.slider-card-social-media{
    margin: 10px 0px 5px 10px;
    width: 22px;

}
.slider-card-social-media-section{
    padding-left: 30%;
} 