@import url('https://fonts.googleapis.com/css2?family=Arimo&display=swap');


.about{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 60px;
  flex-wrap: wrap;
}

.parent {
  background: rgb(203,139,78);
  background: linear-gradient(90deg, rgba(203,139,78,1) 0%, rgba(128,179,232,1) 90%); 
  max-width: 600px;
  max-height: 600px;
  margin: 10vh auto;
  position: relative;
  text-align: center;
  left: 10px;
}

.child {
  top: calc(-36px);
  /* max-height: 100vh; */
  position: relative;
  /* left: calc(50%); */
  /* text-align: center; */

  /* height: 100px; */
}
.pictures{
   /* display: inline; */
    /* float: left; */
    vertical-align:top;
    /* display: inline-block; */
    /* position: relative; */
    max-width: 600px;
}

@media (max-width: 400px) {
  .child{
    top: calc(-5vh);

    height: 70vh;
  }
  .parent{
    height: 60vh;
  }
}

@media (max-width: 1230px) {
  .text{
    text-align: center;
    padding-left: 0px;
    padding-right: 0px;
  }
  .pictures{
    /* width: 80%; */
    /* max-width: 80%; */
  }
  .parent {
    background: rgb(203,139,78);
    background: linear-gradient(90deg, rgba(203,139,78,1) 0%, rgba(128,179,232,1) 90%); 
    width: 86vw;
    max-height: 70vh;
    margin: 10vh auto;
    position: relative;
    text-align: center;
    left: 0;
  }
  .child{
    max-width: 70vw;
    max-height: 80vh;
  }
  .about{
    padding: 0 10px;
  }
  
}

@media (min-width: 1230px) {
    .about{
        display: grid;
    }
    .pictures{
        margin-left: 30px;
        grid-row-start:1 ;
        grid-column-start: 1;
        grid-column-end: 3;
    }
    .text{

        padding-left: 20px;
        grid-column-start: 4;
        grid-column-end: 5;
        max-width: 500px ;
        
    }
}
.text{
    padding-left: 20px;
    padding-top: 60px;
    /* display: inline-block; */
  /* display: inline-grid; */
  /* vertical-align: right; */
}
.text h5{
    font-family: 'Futura';
    font-style: normal;
    font-stretch: condensed;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;

    /* identical to box height, or 178% */
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #80B3E8;
}
.text h1{
    font-family: 'Futura';
    font-style: normal;
    font-stretch: condensed;
    font-weight: 600;
    font-size: 56px;
    line-height: 64px;

    letter-spacing: -1px;

    color: #333333;
}
.text p{
  font-family: 'Arimo', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #333333;
  padding-bottom: 10px;
}


@media (max-width: 400px){
  .text p{
    font-size: 15px  !important;
    line-height: 10px;
  }
}