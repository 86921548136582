.twoElmContainer{
    margin-left: auto;
    margin-right: auto;
    display: flex;
    width: 80vw;
    justify-content: space-between;
    align-items: center;
    margin-bottom: max(14vh, 90px);
    gap: 5px;
}

.blockElement{
    width: 45%;
}

@media only screen and (max-width: 1100px) {
    .blockElement{
        width: 48%;
    }
}

@media only screen and (max-width: 600px) {
    .twoElmContainer{
        flex-wrap: wrap;
        gap: 75px;
        justify-content: center;
    }

    .reverse{
        flex-wrap: wrap-reverse;
    }

    .blockElement{
        width: 80vw;
    }
}