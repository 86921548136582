.home > div {
    margin: 0 0 60px 0;
}

.speakers-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Futura;
}

.speakers-container h4 {
    color: #80B3E8;
    letter-spacing: 2px;
    font-weight: 500;
    margin-bottom: 10px;
}

.speakers-container h2 {
    margin-top: 10px;
    margin-bottom: 40px;
    color: #333333;
    font-weight: 500;
    font-size: 56px;
}

.home-container h3 {
    margin-bottom: 40px;
    font-family: 'Futura BdCn BT';
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    letter-spacing: -2px;
    color: #333333;
}