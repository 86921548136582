.textBlock-title{
    margin-bottom: 30px;
    font-family: 'Futura BdCn BT';
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    letter-spacing: -2px;
    color: #333333;
}

.textBlock-body{
    font-family: "Arimo";
    color: #333333;
    font-family: 'Arimo';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #333333;
}

.gras{
    font-family: Futura;
    font-weight: 400;
}


@media only screen and (max-width: 800px){
    .textBlock-title{
        font-size: 2rem;
    }

    .textBlock-body{
        font-size: 1rem;
    }
}
