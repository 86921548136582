.ActivityDetails{
    margin: 10px 20px;
    text-align: center;
    z-index: inherit;

}

.ActivityDetails  h2,h1,p{
    margin-bottom: 10px;
}
.ActivityDetails .SpeakerCardContainer{
    margin: 10px auto;
    display: grid;
    text-align: start;
    grid-template-columns: auto;
    justify-items: center;
    align-items: center;
    width: 70%;
    max-width: 360px;
    height:280px;
}
.title{
    font-family: FuturaBoldCondesedBT;
    font-size: 1.3em;
}
.secondarytitle{
    font-family: futura;
    font-weight: 400;
    font-size: 1.3em;
}


.ActivityDetails hr{
    width: 40%;
    height: 2px;
    background-color: black;
    border: 0px;
    margin: 0 auto;
}

.ActivityDetails > .dateTime > p{
    display: inline;
    line-height: 1.6em;
    font-size: 1.2em;
    font-family: futuraLtBt;
}
.ActivityDetails > div{
    margin: 10px 0;
}
.synopsis p{
    width: 80%;
    text-align: justify;
    margin: 0 auto;
    text-align-last:center;
    font-size: 1.3em;
    font-family: futuraLtBt;
}
/* @media all and (min-width: 1024px)  {
    .ActivityDetails .SpeakerCardContainer{
        width: 60%;
        grid-auto-rows: 200px;
    }
}

@media all and (min-width: 768px) and (max-width: 1024px) {
    .ActivityDetails .SpeakerCardContainer{
        width: 80%;
        grid-auto-rows: 200px;
    }    
}

@media all and (min-width: 480px) and (max-width: 768px) { 
    .ActivityDetails .SpeakerCardContainer{
        width: 360px;
        grid-auto-rows: 260px;
    }
}

@media all and (max-width: 480px) {
    .SpeakerCardContainer {
        font-size: 60% !important;
        width: 260px;
        grid-auto-rows: 187px;
    }
} */