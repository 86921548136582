.Location {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Futura;
    height: 80vh;
    margin-bottom: 70px;
}

.Location h4 {
    color: #CB8B4E;
    letter-spacing: 2px;
    font-weight: 500;
    margin-bottom: 10px;
}

.Location h2 {
    margin-top: 10px;
    margin-bottom: 40px;
    color: #333333;
    font-weight: 500;
    font-size: 56px;
}

#map {
    height: 100%;
    width: 70vw;
}

.leaflet-container {
    height: 100%;
}

@media screen and (max-width: 480px) {
    #map {
        width: 85vw;
        height: 50vh;
    }
}

@media screen and (max-width: 480px) {
    .sponsors-container h2 {
        font-size: 40px;
    }
}