
.sponsor-logo-row {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 36px;
    width: 90vw;
    margin-bottom: 36px;
}

.sponsor-container {
    position: relative;
}
.sponsor-logo-row .sponsor-container img {
    display: block;
    border-radius: 20px;
}
.sponsor-logo-row .sponsor-container .platinum {
    width: 350px;
    height: 197px;
    box-shadow: 1px 1px 20px 1px #11fd74;
}
.sponsor-logo-row .sponsor-container .gold {
    width: 300px;
    height: 168px;
    box-shadow: 1px 1px 20px 1px #fae600;
}
.sponsor-logo-row .sponsor-container .silver {
    width: 250px;
    height: 140px;
    box-shadow: 1px 1px 20px 1px #b2ccd8;
}
.sponsor-logo-row .sponsor-container .society {
    width: 250px;
    height: 140px;
    box-shadow: 1px 1px 20px 1px #b2ccd8;
}
.sponsor-logo-row .sponsor-container .bronze {
    width: 250px;
    height: 140px;
    box-shadow: 1px 1px 20px 1px #ba7518;
}

.sponsor-type-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: .5s ease;
    background-color: #cb8a4ed9;
    border-radius: 20px;
}

.sponsor-type-overlay:hover {
    opacity: 1;
}

.sponsor-type-overlay span {
    font-family: "Futura Hv BT";
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;

}

