.currencyInputArea{
    width: 35%;
    height: 35px;
    overflow: hidden;
    position: relative;
}

.currencyInputArea-input{
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border: #C4C4C4  solid 1px;
    color: #202020;
    font-size: 1rem;
    font-family: "Arimo";
    padding: 10px;
    padding-left: 15%;
    box-sizing:border-box;
}

.currencyInputArea-curr{
    position: absolute;
    height: 100%;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #E8E8E8;
    padding-left: 5%;
    padding-right: 5%;
    border-radius: 5px;
    font-family: "Arimo";
    font-size: 0.9rem;
    cursor: pointer;
}


@media only screen and (max-width: 550px) {
    .currencyInputArea{
        width: 40%;
    }
}

@media only screen and (max-width: 500px){
    .currencyInputArea{
        width: 70%;
    }
}