.testimonials-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.testimonials-container h4 {
    color: #80B3E8;
    letter-spacing: 2px;
    font-weight: 500;
    margin-bottom: 10px;
}

.testimonials-container h2 {
    margin-top: 10px;
    color: #333333;
    font-weight: 500;
    font-size: 56px;
}

@media screen and (max-width: 480px) {
    .testimonials-container h2 {
        font-size: 40px;
    }
}

.testimonial.activeSlide {
    opacity: 1;
    transform: translateX(0);
}

.testimonial.lastSlide {
    transform: translateX(-100%);
}

.testimonial.nextSlide {
    transform: translateX(100%);
}

.section-center {
    margin-top: 4rem;
    width: 80vw;
    height: 450px;
    position: relative;
}

.testimonials-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Futura;
    margin-bottom: 100px;
}

.testimonials-container h4 {
    color: #80B3E8;
    letter-spacing: 2px;
    font-weight: 500;
    margin-bottom: 10px;
}

.testimonials-container h2 {
    margin-top: 10px;
    margin-bottom: 40px;
    color: #333333;
    font-weight: 500;
    font-size: 56px;
}

@media screen and (max-width: 480px) {
    .testimonials-container h2 {
        font-size: 40px;
    }
}

.testimonial {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    transition: all 0.3s linear;
}

.testimonial-text-container {
    width: 40%;
    display: flex;
    flex-direction: column;

}

.testimonial-text-container .description {
    margin-bottom: 60px;
    font-size: 24px;
}

.testimonial-text-container .name {
    font-size: 20px;
}

.testimonial-text-container .role {
    font-family: Futura;
    font-weight: 500;
    font-size: 20px;
    color: rgba(51, 51, 51, 0.5);
}


.prev,
.next {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 20%;
    width: 48px;
    height: 45px;
    border-color: transparent;
    border-radius: 100px;
    cursor: pointer;
}

.prev:hover,
.next:hover {
    background: hsl(21, 62%, 45%);
}

.prev:hover {
    background: #adb7bd;
}

.prev {
    left: 45%;
    background: #F0F2F6;
}

.next {
    background: #CB8B4E;
    right: 45%;
}

/* --------------------------MEDIUM SCREEN CSS -----------------------------*/
@media (min-width: 900px ) and (max-width: 1100px) {

    .testimonial-image-container .main-image {
        width: 350px;
        height: 450px;
    }

    .testimonial-text-container {
        width: 100%;
    }

    .section-center {
        width: 90vw;
    }

    .prev {
        left: 27%;
    }

    .next {
        right: 60%;
    }

}

@media (min-width: 1100px ) and (max-width: 1300px) {

    .testimonial-image-container {
        width: 50%;
    }

    .testimonial-image-container .main-image {
        width: 380px;
        height: 480px;
    }

    .testimonial-image-container .orange-circle {
        left: 0;
    }

    .testimonial-image-container .blue-circle {
        right: 0;
        bottom: 10%;
    }

    .prev {
        left: 27%;
    }

    .next {
        right: 60%;
    }
}

/* --------------------------SMALL SCREEN CSS -----------------------------*/

@media (max-width: 900px) {
    .testimonials-container h2 {
        margin-top: 10px;
        margin-bottom: 0;
    }

    .section-center {
        display: flex;
        flex-direction: column;
        margin-top: 4rem;
        width: 100vw;
        height: 450px;
        position: relative;
    }

    .testimonial {
        overflow: hidden;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.3s linear;
    }

    .testimonial-text-container {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .testimonial-text-container .description {
        font-family: Arimo;
        font-size: 24px;
        font-weight: 400;
        line-height: 32px;
        margin-bottom: 30px;
    }

    .testimonial-text-container .name {
        font-size: 20px;
    }

    .testimonial-text-container .role {
        font-size: 20px;
    }

    .prev,
    .next {
        bottom: 50px;
    }

    .prev {
        left: 10%;
    }

    .next {
        right: 10%;
    }
}

@media (max-width: 370px) {
    .testimonial-text-container .description {
        font-size: 20px;
    }
}