.schedule-text-section {
    display: flex;
    flex-direction: column;
    /* background-color: red; */
    align-items: flex-start;
}

.schedule-text-section svg {
    width: 20%;
    height: 20%;
}

.schedule-text-section svg *{
    width: 100%;
    height: 100%;
    fill: #B95346;
}

.schedule-text-section-title {
    font-family: 'Futura BdCn BT';
    font-size: clamp(2rem, 3.2vw, 3rem);
    color: #333333;
}

.schedule-text-section-comment {
    font-family: 'Futura Hv BT';
    color: #999999;
    font-size: clamp(0.95rem, 2vw, 1.2rem);
}

.schedule-text-section-content {
    font-size: clamp(0.95rem, 2vw, 1.2rem);
}

.map2 {
    height: 100% !important;
    width: 100% !important;
}
