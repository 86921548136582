.curveContainer{
    width: 100%;
    position: relative;
}

.bgPicture{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.curveContent{
    position: relative;
    z-index: 2;
    height: 100%;
    width: 50%;

    margin-left: auto;
    margin-right: auto;
    padding-top: 20vh;
    padding-bottom: 20vh;

    color: #FFF;
    
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sectionContent{
    margin-top: 3vh;
    text-align: center;
    font-family: "Arimo";
    font-size: 0.9rem;
    line-height: 1.2rem;
}

.sectionTitle{
    font-size: 3rem;
}

.mobile{
    display: none;
}

@media only screen and (max-width: 550px){
    .desktop{
        display: none;
    }

    .mobile{
        display: block;
    }

    .curveContent{
        width: 80%;
    }
}