@font-face {
  font-family: Futura;
  src: url("./assets//fonts/Futura\ Light\ font.ttf");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: Futura;
  src: url("./assets//fonts/Futura\ Light\ Italic\ font.ttf");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: Futura;
  src: url("./assets//fonts/Futura\ Book\ font.ttf");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: Futura;
  src: url("./assets//fonts/Futura\ Book\ Italic\ font.ttf");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: Futura;
  src: url("./assets//fonts/futura\ medium\ bt.ttf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Futura;
  src: url("./assets//fonts/futura\ medium\ condensed\ bt.ttf");
  font-weight: 400;
  font-stretch: condensed;
}
@font-face {
  font-family: Futura;
  src: url("./assets//fonts/Futura\ Bold\ font.ttf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: Futura;
  src: url("./assets//fonts/Futura\ Bold\ Italic\ font.ttf");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: Futura;
  src: url("./assets//fonts/Futura\ Extra\ Black\ font.ttf");
  font-weight: 600 bold;
  font-style: normal;
}
@font-face {
  font-family: Futura;
  src: url("./assets//fonts/Futura\ Extra\ Black\ font.ttf");
  font-weight: 700 bolder;
  font-style: normal;
}
@font-face {
  font-family: Futura;
  src: url("./assets//fonts/Futura\ XBlk\ BT.ttf");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Amatica SC';
  font-style: normal;
  font-weight: 400;
  src: local('Amatica SC'), url('https://fonts.cdnfonts.com/s/29880/AmaticaSC-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Amatica SC';
  font-style: normal;
  font-weight: 700;
  src: local('Amatica SC'), url('https://fonts.cdnfonts.com/s/29880/AmaticaSC-Bold.woff') format('woff');
}
@font-face {
  font-family: 'FuturaBoldCondesedBT';
  src: url("./assets//fonts/FuturaBoldCondensedBT.ttf");
}
@font-face {
  font-family: futuraLtBt;
  src: url("./assets/fonts/futura\ light\ bt.ttf");
}


body {
  margin: 0;
  font-family: 'Futura Md BT', sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}



::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}