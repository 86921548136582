.sponsoring .twoElmContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: max(14vh, 90px);
    margin-top: 100px;
    width: 90vw;
}

.sponsoring {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.sponsoring h2 {
    margin-bottom: 40px;
    font-family: 'Futura BdCn BT';
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    letter-spacing: -2px;
    color: #333333;
}

@media only screen and (max-width: 900px) {
    .sponsoring .twoElmContainer {
        gap: 50px;
        justify-content: center;
    }
}

.sponsoring .textBlockContainer {
    min-width: 45vw;
    flex: 2;
    margin-right: 100px;
}

.sponsoring .textBlock-title {
    margin-bottom: 30px;
    font-family: 'Futura BdCn BT';
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    letter-spacing: -2px;
    color: #333333;
}

.sponsoring .textBlock-body {
    font-family: "Futura";
    color: #333333;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 32px;
}

.sponsoring .gras {
    font-family: Futura;
    font-weight: 400;
}


@media only screen and (max-width: 900px) {
    .sponsoring .textBlockContainer {
        margin-right: 0;
    }

    .sponsoring .textBlock-title {
        font-size: 2rem;
    }

    .sponsoring .textBlock-body {
        font-size: 1rem;
    }
}

.sponsoring .dossierContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    height: 100%;
}