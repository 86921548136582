.decorated-image-container{
    height: 100%;
    position: relative;
}

.decorator{
    width: 100%;
    height: 100%;
}

.image-container{
    background: linear-gradient(269.91deg, #80B3E8 0.07%, #CB8B4E 99.91%);

    width: 90%;
    height: 100%;
}

.image-container img{
    position: relative;
    top: 40px;
    left: 6.5vw;
    width: 90%;
    height: 90%;
}