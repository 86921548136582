header {
    width: 100vw;
    position: relative;
}

.headerWave {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -50000;
    width: 90vw
}

.headerWavePage {
    width: 80vw;
}

header .logo {
    margin: 50px;
}

.beb {
    max-width: 90vw;
    min-width: 32vw;
    transform: translate(-100px, -100px) rotate(1.89deg);
}

.descr {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 40px;
    max-width: 900px;
}

.descrpage {
    align-items: center;
    text-align: center;
}

.descrpage h1 {
    align-self: center;
    font-size: 128px;
    line-height: 80px;
    font-stretch: condensed;
    font-weight: 800;
    font-size: 125px !important;
    /* or 62% */

    text-align: center;
    letter-spacing: -2px;
    margin-bottom: 30px !important;
}

.descrpage p {
    font-size: 24px !important;
}

.descr h1 {
    font-family: 'Futura';
    font-style: normal;
    font-weight: 700;
    font-size: 90px;
    line-height: 80px;
    /* identical to box height, or 89% */

    letter-spacing: -2px;

    color: #333333;
    margin-bottom: 4px;

}

.descr p {
    font-family: 'Futura Hv BT';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 32px;
    width: 100%;

    margin-bottom: 40px;

    color: #787878;

}

.descr .date {
    font-size: 24px;
    color: #333;
    margin: 10px 0 10px 0;
    fill-opacity: .7;
    width: fit-content;
    font-weight: 700 !important;
}


.welcome {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100vw;
    padding: 40px 0;
}

.welcome p {
    max-width: 45vw;
}


@media screen and (max-width: 1230px) {
    .welcome {
        flex-direction: column;
        flex-wrap: wrap;
    }

    .beb {
        transform: translate(0, 50px);
    }

    .descr {
        margin: auto;
    }

    .descr h1 {
        font-family: 'Futura';
        font-style: normal;
        font-weight: 700;
        font-size: 50px;
        color: #FBDAA4;
        max-width: 90vw;
    }

    .descr p {
        font-family: 'Futura Hv BT';
        font-style: normal;
        font-weight: 400;
        font-size: 16px !important;
        line-height: 32px;
        max-width: 90vw;
        color: #FFFFFF;
    }

    .descr .date {
        color: #FFFFFF;
    }

    .descrpage h1 {
        color: #333333;
        font-size: 80px !important;
        margin-bottom: 5px !important;
    }

    .descrpage p {
        color: #787878;
        font-size: 13px !important;
    }

    .headerWave {
        min-width: 200vw;
    }

    .logo {
        width: 70px;
    }
}

@media screen and (max-width: 600px) {
    .descrpage h1 {
        font-size: 55px !important;
    }


    .descr h1 {
        font-size: 25px;
        line-height: 80px;
    }

    .descr p {
        margin-bottom: 0;
        font-size: 12px;
        max-width: 90vw;
    }

}

@media screen and (max-width: 1230px) and (min-width: 750px ) {
    .descrpage h1 {
        margin-top: 50px;
    }

}