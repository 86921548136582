.countdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-family: Futura;
    flex-wrap: wrap;
    width: 100vw;
    height: 400px;
    background-color: #333333;
    padding: 30px 0;
}

.countdown h4 {
    color: #80B3E8;
    letter-spacing: 2px;
    font-weight: 500;
    margin-bottom: 10px;
    padding-top: 50px;
}

.countdown h2 {
    font-family: "Futura Hv BT";
    margin-top: 10px;
    margin-bottom: 40px;
    color: #fff;
    font-weight: 300;
    font-size: 42px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
}

.timer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 800px;
}

.timer div {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin: 0 50px;
}

.timer div:first-of-type {
    padding-right: 50px;
}

.numbers {
    font-family: 'Futura BdCn BT';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    letter-spacing: -2px;
    color: #FFFFFF;
}

.unit:last-child {
    font-family: 'Futura BdCn BT';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #CB8B4E;
}

.facebook-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
}

.facebook-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

@media screen and (max-width: 1230px) {
    .countdown h2 {
        font-size: 30px;
    }

    .numbers {
        font-size: 30px;
    }

    .timer div {
        margin: 0 20px;
    }

    .timer div:first-of-type {
        padding-right: 30px;
    }
}

@media only screen and (max-width: 550px) {
    .countdown h2 {
        font-size: 20px;
    }

    .numbers {
        font-size: 20px;
        padding: 0 10px;
    }

    .timer div {
        margin: 0 5px;
    }

    .timer div:first-of-type {
        padding-right: 10px;
    }

    .countdown {
        height: 300px;
    }

    .countdown {
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
}

